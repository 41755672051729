.head-photos-title {
  padding-top: 20px;
  padding-bottom: 25px;
  text-align: center;
}

.certificate-img {
  width: 250px;
}

.img-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certificates-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.card-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-crt {
  animation: anicrt linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}
@keyframes anicrt {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
