iframe {
  width: 100%;
  height: 520px;
  margin-top: 20px;
  border-radius: 8px;
}

.cta-container {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: right;
}

.pname {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.link-p {
  color: #;
  text-decoration: none;
  transition: color 0.3s;
  color: #fff;
}

.link-p:hover {
  color: #c59e6b;
}
@media (max-width: 600px) {
  .pname {
    font-size: 10px;
    font-weight: bold;
    color: #333;
  }

  .cta-container {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
}
