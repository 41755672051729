.card-container2 {
  height: 300px;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures space between elements */
}
.header-container2 {
  padding-left: 18px;
  display: flex;
  align-items: center;
}
.head-image2 {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.headname2 {
  padding-top: 8px;
  color: black;
  font-size: 2.4em;
  margin-left: 2px;
}
.books-card {
  margin: 20px;
}
.card-heading2 {
  margin: 20px 0;
  text-align: center;
}
.card-paragraph2 {
  text-align: center;
  margin: 20px 0;
  flex-grow: 1; /* Allows the paragraph to take up space */
}
.card-button2 {
  align-self: flex-end; /* Aligns button to the right */
  padding: 10px 20px;
  background-color: #d7bf9f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.card-button2:hover {
  background-color: #c59e6b;
}
